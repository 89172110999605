import { CMMSAPI } from "./cmms-api";
import type { Tenant, Response } from "types";

export interface GetTenantsParams {
  page?: number;
  search?: string;
}

export interface AddTenantRequestBody {
  company_name: string;
  customer_info: {
    name: string;
    user_limit: number;
    totango_id: string;
  };
  customer_emails?: string[];
}

export interface UpdateTenantRequestBody {
  id: number;
  company_name: string;
  customer_info: {
    name: string;
    users_limit: number;
    totango_id: string;
  };
}

const tenantsApi = CMMSAPI.injectEndpoints({
  endpoints: (builder) => ({
    getTenants: builder.query<Response<Tenant[]>, GetTenantsParams>({
      query: (params) => ({
        url: "tenants",
        params,
      }),
      providesTags: () => ["tenants"],
    }),
    getTenant: builder.query<Response<Tenant>, string>({
      query: (id: string) => ({
        url: `tenants/${id}`,
      }),
      providesTags: () => ["tenants"],
    }),
    markTenantForDeletion: builder.mutation<Response<boolean>, number>({
      query: (id: number) => ({
        url: `tenants/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["tenants"],
    }),
    confirmTenantDeletion: builder.mutation<Response<boolean>, number>({
      query: (id: number) => ({
        url: `tenants/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["tenants"],
    }),
    updateTenant: builder.mutation<Response<Tenant>, UpdateTenantRequestBody>({
      query: (params: UpdateTenantRequestBody) => ({
        url: `tenants/${params.id}`,
        method: "PATCH",
        params,
      }),
      invalidatesTags: ["tenants"],
    }),
    addTenant: builder.mutation<Response<Tenant>, AddTenantRequestBody>({
      query: (body) => ({
        url: "tenants",
        method: "POST",
        body,
      }),
      invalidatesTags: ["tenants"],
    }),
  }),
});

export const {
  useGetTenantQuery,
  useGetTenantsQuery,
  useAddTenantMutation,
  useUpdateTenantMutation,
  useConfirmTenantDeletionMutation,
  useMarkTenantForDeletionMutation,
  usePrefetch: usePrefetchTenants,
} = tenantsApi;
